import { ref } from 'vue';

const sideBarOptions = ref([
    {
        label: 'Contract Sync',
        componentName: 'ContractSync'
    },
    {
        label: 'Manual SKU Push',
        componentName: 'ManualSkuPush'
    },
    {
        label: 'Resend Notification',
        componentName: 'ResendNotification'
    },
    {
        label: 'Auto Assignment',
        componentName: 'AutoAssignment'
    },
    {
        label: 'User Permissions',
        componentName: 'UserPermissions'
    }
]);

const selectedOption = ref(sideBarOptions.value[0]);

export default () => {
    const setSelectedOption = (newOption) => {
        selectedOption.value = newOption;
    };

    return {
        selectedOption,
        sideBarOptions,
        setSelectedOption
    };
};
